<template>
  <page fluid="lg" title="Editar indicador">
    <formulario ref="frm" :indicador="indicador" :relacionados="relacionados" @submit="actualizar"> </formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiIndicadores from "@/services/indicadores.js";

export default {
  components: { Formulario },
  data() {
    return {
      indicador: {},
      relacionados: [],
    };
  },
  methods: {
    async cargar(id) {
      const response = await apiIndicadores.cargarConRelaciones(id);
      this.indicador = response.indicador;
      this.relacionados = response.relacionados;
    },
    async actualizar({ indicador, relacionados, documento }) {
      const response = await apiIndicadores.actualizar(indicador, relacionados);

      if (documento.name) {
        await apiIndicadores.importar(response.id, documento);
      }

      this.$emit("userMessage", "Se ha actualizado el indicador");
      this.$router.push({ path: "/administracion/indicadores" });
    },
  },
  mounted() {
    this.cargar(this.$route.params.id);
  },
};
</script>
